




























































import { RegistroOperacional } from '@/core/models/operacional';
import { PageBase } from '@/core/models/shared';
import { RegistroOperacionalService } from '@/core/services/operacional';
import { AlertSimpleErr } from '@/core/services/shared/AlertService';
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';


@Component
export default class RelatorioOS extends PageBase { 

    service = new RegistroOperacionalService();
    item: RegistroOperacional = new RegistroOperacional();

    overlay: boolean = false;

    mounted() {
        const filter = this.$route.query;
        this.overlay = true;
        this.service.ListarComFiltro(0,-1,[],[],null,[],filter, "Proposta.Cliente, Porto, Proposta.Responsavel, Embarcacao, Situacao")
            .then(
                res=>{
                    this.item = res.data.items;
                },
                err => AlertSimpleErr("Aviso!", err)
            ).finally(() => {
                this.overlay = false;
            });
    }
}
